import moment from "moment";

// format currency
export const formatCurrencyIDR = (value) => {
  if (value == 0 || value == null || value == "" || value == undefined) {
    return 0;
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(value);
};

// format without currency
export const formatWithoutCurrency = (value) => {
  if (value == 0 || value == null || value == "" || value == undefined) {
    return "-";
  }
  return new Intl.NumberFormat("id-ID").format(value);
};

// date format
export const dateFormat = (value, format) => {
  if (value == 0 || value == null || value == "" || value == undefined) {
    return "-";
  }
  return moment(value).format(format);
};

// return - if value is null
export const checkIfempty = (value) => {
  if (value == 0 || value == null || value == "" || value == undefined) {
    return "-";
  }
  return value;
};
